export default {
  siteTitle: 'Get bonus',
  blogerName: 'PIPA',
  socialsList: [
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/filipasantos'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/filipasantoss__'
    }
  ],
  projects: [
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c6535b3db',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c75648cd9',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/c63a7f4a1',
      gameTitle: 'Savage Buffalo Spirit Megaways',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/c390e26b0',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c62175f2a',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c35dd9d2c',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/c7e74945a',
      gameTitle: 'JET AIR (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c979a849d',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c0745b93a',
      gameTitle: 'Doors Of Fresh (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>PIPA</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'PIPA',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
